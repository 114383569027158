.gradient {
  min-height: 100vh;
  background-image: linear-gradient(rgba(239, 239, 239, 1), rgba(219, 251, 251, 1));
}

.wave {
  height: 6rem;
  background-image: url("../../assets/images/logo-wave.png")
}

.noWave {
  height: '2rem';
}
.toggle {
  transition: all 0.35s ease;
}

.toggle[aria-expanded="true"] {
  transform: rotateZ(-90deg);
}

.accordion-content {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.accordion-content[aria-expanded="true"] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}